html {
  scroll-behavior: smooth;
}
body {
  font-family: "Inter", sans-serif;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
button{
  outline: none !important;
  border: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

h1 {
  font-size: 80px !important;
  font-style: normal !important;
}

.grey {
  color: #8ca0b9 !important;
}

.ptb20 {
  padding: 20px 0;
}

h2 {
  font-size: 42px !important;
  font-style: normal !important;
}

p {
  font-size: 16px;
}
button {
  cursor: pointer;
}
.ccolor {
  color: #7949ff;
}
.inpoo {
  height: 62px;
  border-radius: 5px;
  background-color: #fff;
}
.btn-common {
  padding: 15px 27px;
  background: linear-gradient(180deg, #3051ff 0%, #0a2ad0 100%);
  margin: 10px 0;
  border-radius: 10px;
  // padding: 10px 15px;
  color: #fff;
  text-decoration: none;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  &:hover {
    // background: #0D0918;

    // color: #fff;
  }
  .border-right {
    border-right: 1px solid #fff;
  }
}
input {
  background: #e1e7ec;
  border-radius: 16px;
  border: none;
  outline: none;
  padding: 10px 10px;
  text-align: center;
  color: #6e757c;
  &::placeholder {
    color: #6e757c;
  }
}

.btn-common2 {
  padding: 15px 27px;

  background: #f7f7f8;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 10px 0;
  padding: 13px 49px;
  color: #2c353d;
  text-decoration: none;
  min-width: 200px;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid #2c353d;
}
.btn-common3 {
  padding: 15px 27px;
  background: #3051ff !important;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 10px 0;
  padding: 15px 27px;
  color: #fff;
  text-decoration: none;
  // min-width: 200px;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid #2c353d;
}

.btn-common4 {
  padding: 15px 27px;
  background: #3051ff !important;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 10px 0;
  padding: 15px 27px;
  color: #fff;
  text-decoration: none;
  // min-width: 200px;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid #2c353d;
  pointer-events: none;
  opacity: 0.5;
}
.ptb {
  padding: 60px 0;
}

.yellow {
  color: #e09414;
}
.border-img {
  border-radius: 10px;
}

.common {
  color: #3051ff;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}
.white {
  color: #fff !important;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}
//modal
.modal {
  .modal-content {
    border-radius: 32px;
    a {
      color: #6e757c;
    }
    .modal-header {
      border-bottom: none;
      padding: 0;
      h5 {
        font-weight: 600;
        font-size: 40px;
        text-align: center;
        color: #2c353d;
      }
    }
  }
  .modal-body {
    padding: 12px 52px;
  }
}
.accordion {
  .card-header {
    background: transparent;
  }
  .card {
    margin-top: 10px;
    border: none;
    background: transparent;
  }
  .btn-link {
    font-weight: 400;
    color: #000;
    background-color: transparent;
    text-decoration: none;
  }
}
@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }

    .form-inline {
      flex-flow: unset !important;

      /* align-items: center; */
      flex-direction: column !important;
      justify-content: start !important;
      /* text-align: left; */
    }
    .noBorder {
      border-right: none !important;
    }
  }

  .main-footer {
    .inner-icon {
      padding: 10px 0;
      ul.yo {
        text-align: center;
      }
    }

    .text-right {
      text-align: center !important;
    }
  }

  .main-about .main-tile .inner-tile li {
    padding-right: 0px;
  }

  .main-team {
    .inner-content {
      .carousel-control-next {
        right: 0 !important;
        left: 40px !important;
        top: -44px !important;
        height: 30px;
      }
      .carousel-control-prev {
        right: 0 !important;
        left: 0px !important;
        top: -44px !important;
        height: 30px;
      }
    }
  }

  // .main-navbar {
  //   .modal-dialog {
  //     position: relative;
  //     width: 48%;
  //     margin: .5rem;
  //     pointer-events: none;
  // }
  // }

  .main-about {
    .inner-img {
      img {
        margin: 20px auto !important;
      }
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  h2 {
    font-size: 1.7rem !important;
    font-weight: bold;
    text-align: center;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }
  .owl-carousel.owl-loaded {
    display: block;
    height: 400px;
  }

  .community {
    .main-tile1 {
      background: rgba(30, 32, 41, 0.4);
      border: 2px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      backdrop-filter: blur(50px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 30px;
      .inner-value {
        margin: 5px 7px !important;
      }
      .brdr {
        height: 60px;
        width: 1px;
        background: grey;
      }
    }
  }
  .modal {
    .modal-content {
      border-radius: 32px;
    }
    .modal-body {
      padding: 20px 10px;
    }
  }
}
// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }
  .owl-carousel.owl-loaded {
    display: block;
    height: 400px;
  }

  h1 {
    font-size: 3.5rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .ptb {
    padding: 20px 0;
  }
  .community {
    .main-tile1 {
      background: rgba(30, 32, 41, 0.4);
      border: 2px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      backdrop-filter: blur(50px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 30px;
      .inner-value {
        margin: 5px 7px !important;
      }
      .brdr {
        height: 60px;
        width: 1px;
        background: grey;
      }
    }
  }
}
// 12 pro max
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }
  .owl-carousel.owl-loaded {
    display: block;
    height: 400px;
  }

  h1 {
    font-size: 3.5rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .ptb {
    padding: 20px 0;
  }

  .community {
    .main-tile1 {
      background: rgba(30, 32, 41, 0.4);
      border: 2px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      backdrop-filter: blur(50px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 30px;
      .inner-value {
        margin: 5px 7px !important;
      }
      .brdr {
        height: 60px;
        width: 1px;
        background: grey;
      }
    }
  }
}

.sk {
  text-align: right !important;
}

.state_errrror {
  color: rgb(235, 87, 87) !important;
  margin: 10px 0px;
}

@media only screen and (max-width: 400px) {
  .btn-common2 {
    padding: 9px 22px;
    background: #f7f7f8;
    // box-shadow: 0px 8px 32px rgb(0 0 0 / 10%);
    border-radius: 10px;
    margin: 10px 0;
    /* padding: 13px 49px; */
    color: #2c353d;
    text-decoration: none;
    min-width: 200px;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    border: 1px solid #2c353d;
  }
}


.uvyyct{
  text-align: center;
  margin-top: 35px;
}

a:hover {
  color:white !important
}



iframe{
  position: unset!important;
  top: 0px!important;
  left: 0px!important;
  width: 0px!important;
  height: 0px!important;
} 

.MuiBackdrop-root.loader{
  z-index: 20000!important;
  color: #fff !important;
}