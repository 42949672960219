.main-navbar {

    // background: #eeeff2;
    .navbar {
        margin-top: 23px !important;
    }

    .navbar-brand {
        padding-top: 0% !important;
    }

    .style-bar {
        width: 26px;
        height: 3px;
        margin: 6px 0;
        border-radius: 4px;
    }

    .btnnav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 12px 45px;
        background: radial-gradient(41.97% 87.5% at 50% 50%, #1FA6F5 0%, #324CF0 100%);
        border-radius: 40px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .btn-login {
        background: transparent;
        border: none;
        margin-right: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #6e757c;
    }

    .navbar-toggler {
        padding: 0.02rem 0.45rem !important;
        background: radial-gradient(41.97% 87.5% at 50% 50%, #1FA6F5 0%, #324CF0 100%);

        .style-bar {
            background-color: #fff;
        }
    }

    .modal-content {
        // background-image: url("../../../Assets/background.png");
        background-color: #000;
        // background-color: yellowgreen;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // background-color: transparent;
        border-radius: 20px;
        border: none;

        .close {
            float: right;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            color: #fff;
            text-shadow: 0 1px 0 #fff;
            opacity: 1.5;
        }

        .pop-content {
            text-align: center;

            .inner-tile {
                background: transparent;
                background-color: #fcd419;
                border: 1px solid #fcd419;
                box-sizing: border-box;
                border-radius: 20px;
                margin: 10px 0px;
                padding: 20px;
                width: 100%;

                &:hover {
                    background-color: #fcd419;
                }
            }
        }
    }

    .form-inline {
        a {
            color: #fff;
            padding: 0px 15px;
            display: inline-block;

            &:hover {
                color: #fcd419;
            }
        }
    }
}

@media (max-width: 600px) {
    .navbar-brand {
        width: 67%;
    }

    .main-navbar {
        .form-inline {
            flex-flow: unset !important;

            /* align-items: center; */
            flex-direction: column !important;
            justify-content: start !important;
            /* text-align: left; */
        }
    }

    .style-bar {
        width: 26px;
    }
}


.walletconnect {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);

    .modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-content {
            background: #FFFFFF;
            border-radius: 20px;
            overflow: hidden;
            padding: 20px;
            margin: 0 auto;
            min-width: 627px;

            .modal-header {
                border-bottom: none;


                .modal-title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 120%;
                    color: #000000;
                    text-align: center;
                    margin-bottom: 38px;
                }

                .btn-close {
                    background: #3456D0;
                    border: none;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 35px;
                    height: 35px;
                }
            }

            .modal-body {
                padding: 0% !important;

                button {
                    background: #FFFFFF;
                    border: 1px solid #FFFFFF;
                    box-sizing: border-box;
                    border-radius: 10px;

                    .maind {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        padding: 16px;
                        background: #FFFFFF;

                        p {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 30px;
                            line-height: 120%;
                            color: #000000;
                            margin-left: 20px;
                        }

                        &:hover {
                            background: #3456D0;
                            border-radius: 10px;

                            p {
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }

        .fvfggg {
            padding: 80px !important;
        }
    }
}


@media(max-width:700px){
    .walletconnect .modal-dialog .modal-content {
        min-width: 100% !important;
    }
}