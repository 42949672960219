.main-banner {
    background: #EEEFF2;
    .head {
        font-style: normal;
        width: 100%;
        font-weight: 600;
        font-size: 79px;
        /* or 125% */
        text-align: center;
        /* State/Bold */
        color: #272E35;
    }
    .contDiv {
        background: linear-gradient(180deg, #F5F5FF 0%, #FFFFFF 100%);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        background-color: red;
        padding: 64px 65px;
        margin-bottom: 30px;
    }
    .banner1{
        background: linear-gradient(180deg, #FFFFFF 0%, #ECECF3 96.42%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        padding-left: 30px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 50px;
        width: 1120px;
        height: 242px;
        justify-content: space-between;
        padding-right: 30px;
        .text{
            h4{
                text-align: start;
                font-style: normal;
                font-weight: 800;
                font-size: 33px !important;
                line-height: 38px;
                color: #000000;
            }
            p{
                text-align: start;
                margin-bottom: 17px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px !important;
                line-height: 34px;
                color: #000000;
            }
        }
        .btn-play{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            a{
                background: transparent;
                margin-bottom: 9px;
            }
        }
        .mbl-img{
            padding-right: 20px;
        }
    }
}
@media (max-width:1199px){
    .main-banner .banner1{
        width: 100%;
    }
    .main-banner .banner1 .text h4{
        font-size: 25px !important;
        text-align: center !important;
    }
    .main-banner .banner1 .text p{
        font-size: 15px !important;
        text-align: center !important;
    }
}
@media (max-width: 991px) {
    .main-banner .banner1{
        height: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px 30px;
    }
    .banner1 .text{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .main-banner .banner1 .btn-play{
        margin-top: 20px;
    }
    .sdvvevdsverwb{
        flex-wrap: wrap!important;
        p{
            color: #6E757C;
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 18px;
        }
    }
    .ghgg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 100%;
    }
    .main-banner .banner1 .text h4{
        font-size: 20px !important;
    }
    .main-banner .banner1 .text p{
        font-size: 14px !important;
    }
    .main-banner .banner1 .text h4{
        text-align: center !important;
    }
    .main-banner .banner1 .text p{
        text-align: center !important;
    }
}
@media (max-width: 768px) {
    .main_div{
        flex-wrap: wrap;
        // justify-content: space-around;
        // text-align: center;
        margin: 20px 27px !important;
    }
}
@media (max-width: 700px) {
    .main_div{
        display: block;
        // justify-content: space-around;
        // text-align: center;
        margin: 20px 27px !important;
        .image_main_div{
            text-align: center;
        }
    }
}
@media (max-width: 601px) {
    .sdvvevdsverwb{
        justify-content: center!important;
        p{
            color: #6E757C;
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 18px;
        }
    }
    .main-banner .bannerInner1 {
        width: 100%;
    }
    .main-banner{
        .contDiv {
           padding: 30px 2px;
           margin-bottom: 30px;
       }
       .pheado{
           margin-top: 50px;
           font-size: 25px;
       }
       .pheado111{
        font-size: 23px;
        color: black;
        margin-top: 42px;
    }
    }
    .sdvvevdsverwb h5{
        text-align: center !important;
    }
    .main-banner .banner1{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}
.hhhhhhhhhh{
    text-align: initial !important;
}
.shbdshbdshd{
    padding-bottom: 0px!important;
}
.asjbahsdb{
    width: 20px;
    height: 20px;
}
.main_div{
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    .common_img{
        margin: 20px 0px;
        border-radius: 20px;
    }
}
@media (max-width: 426px) {
    .main_div{
        display: block;
        // justify-content: space-around;
        // text-align: center;
        // margin: 20px 0px;
        .common_img{
            padding: 0px 20px;
        }
    }
    .main-banner .banner1{
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        height: unset;
    }
    .btn-play{
        margin-top: 20px;
    }
    .main-banner .banner1 .text h4{
        font-size: 12px;
    }
    .main-banner .banner1 .text p{
        font-size: 10px;
    }
}
.main{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}
.sdvvevdsverwb{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .data{
        margin: 20px;
    }
    .data3{
        margin: auto 26px;
        p{
            color: #283036;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 18px;
        }
        h5{
            color: #6E757C;
            margin-top: -15px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
        }
    }
    .data2{
        margin: 10px;
        p{
            color: #6E757C;
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 18px;
        }
        h5{
            font-weight: 700;
            font-size: 28px;
            line-height: 17px;
        }
    }
    p{
        color: #6E757C;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 18px;
    }
    .border{
        background: #DFDFDF;
        width: 2px;
        height: 28px;
    }
    h5{
        font-weight: 700;
        font-size: 18px;
        line-height: 17px;
    }
}
.cont-div1{
        position: relative !important;
        z-index: 1;
        background: linear-gradient(180deg, #F5F5FF 0%, #FFFFFF 100%) !important;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1) !important;
        border-radius: 24px !important;
        margin-bottom: 30px !important;
        width: 535px !important;
        margin: 0 auto !important;
        margin-bottom: 40px !important;
        padding: 0 !important;
    .sdvvevdsverwb{
        height: 282px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        h5{
            margin-top: 53px;
            margin-bottom: 110px;
            font-style: normal;
            font-weight: 800 !important;
            font-size: 24px !important;
            line-height: 17px !important;
            color: #000000 !important;
        }
        .card-bg{
            img{
                position: absolute;
                bottom: 0;
                right: 0px;
                z-index: -1;
            }
        }
        .last-card{
            h5{
                margin: 0 !important;
                padding-top: 51px;
                padding-bottom: 64px;
                text-align: center;
                font-style: normal;
                font-weight: 800 !important;
                font-size: 24px !important;
                line-height: 24px !important;
                color: #000000 !important;
            }
        }
        .data{
            margin: 20px;
        }
        .data3{
            margin: auto 26px;
            p{
                color: #283036;
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                margin-bottom: 18px;
            }
            h5{
                color: #6E757C;
                margin-top: -15px;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
            }
        }
        .data2{
            margin: 10px;
            p{
                color: #6E757C;
                font-weight: 500;
                font-size: 18px;
                line-height: 18px;
                margin-bottom: 18px;
            }
            h5{
                font-weight: 700;
                font-size: 28px;
                line-height: 17px;
            }
        }
        p{
            color: #6E757C;
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 18px;
        }
        .border{
            background: #DFDFDF;
            width: 2px;
            height: 28px;
        }
        h5{
            font-weight: 700;
            font-size: 18px;
            line-height: 17px;
        }
    }
}
@media(max-width:1199px){
    .main-banner .contDiv{
        width: 421px;
    }
    .sdvvevdsverwb{
        width: 421px !important;
    }
}
@media(max-width:991px){
    .sdvvevdsverwb{
        width: 100% !important;
    }
    .sdvvevdsverwb .card-bg img{
        right: 0 !important;
    }
    .contDiv .sdvvevdsverwb{
        flex-direction: unset;
        height: unset;
        padding-bottom: 30px;
    }
}
@media(max-width:430px){
    .main-banner .contDiv{
        width: 100% !important;
    }
}