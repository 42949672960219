.portal {
    padding-top: 80px;
    padding-bottom: 100px;
    position: relative;

    .topshade {
        position: absolute;
        top: 10%;
        left: 0%;
        width: 348px;
        height: 348px;
        background: #55ADFF;
        opacity: 0.8;
        filter: blur(160px);
        z-index: -1;
    }

    .bottomshade {
        position: absolute;
        bottom: 0%;
        right: 0%;
        width: 348px;
        height: 348px;
        background: #55ADFF;
        opacity: 0.8;
        filter: blur(160px);
        z-index: -1;
    }

    .mainban {
        .heading {
            .mainhead {
                font-style: normal;
                font-weight: 700;
                font-size: 50px;
                line-height: 120%;
                text-align: center;
                color: #272E35;
                margin-bottom: 65px;
            }
        }

        .background {
            background: url(../../Assets/background.png);
            border-radius: 20px;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 110px;
            padding-top: 23px;
            padding-left: 50px;

            .innerback {

                h4 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 120%;
                    color: #FFFFFF;
                    margin-top: 27px;
                }

                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 120%;
                    color: #FFFFFF;
                    margin-bottom: 12px;
                    margin-top: 15px;
                }

                ul {
                    li {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 120%;
                        color: #FFFFFF;
                        list-style-type: disc;
                        list-style-position: inside;
                        margin-top: 8px;

                        .bold {
                            font-weight: 700;
                        }
                    }
                }
            }

            .mobileimg {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                margin-top: 23px;

                .mob {
                    width: 237px;
                }
            }
        }

        .innermain {
            .padd {
                padding-top: 90px;
            }

            .imgcard {
                padding-top: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background: rgba(255, 255, 255, 0.5);
                border: 1px solid rgba(52, 86, 208, 0.1);
                backdrop-filter: blur(5px);
                border-radius: 20px;
                position: relative;

                .moneyimg {
                    height: 140px;
                    width: 140px;
                    position: absolute;
                    top: -30%;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 120%;
                    text-align: center;
                    text-transform: uppercase;
                    color: #272E35;
                }

                .bluebtn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 14px 30px;
                    background: radial-gradient(41.97% 87.5% at 50% 50%, #1FA6F5 0%, #324CF0 100%);
                    border-radius: 40px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    margin-top: 25px;
                    margin-bottom: 30px;
                }
            }
        }
    }

    .uppertab {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 40px 35px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(52, 86, 208, 0.1);
        backdrop-filter: blur(5px);
        border-radius: 20px;

        .border-none {
            border: none !important;
        }

        .innertab {
            border-right: 1px solid rgba(52, 86, 208, 0.15);
            padding-right: 56px;

            .para {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 120%;
                text-transform: uppercase;
                color: #929CA7;
            }

            .head {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;
                color: #272E35;
                margin-top: 12px;
                overflow-wrap: anywhere;
            }
        }
    }

    .lowertab {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 35px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(52, 86, 208, 0.1);
        backdrop-filter: blur(5px);
        border-radius: 20px;
        margin-top: 40px;
        padding-bottom: 0% !important;

        .bluehead {
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 120%;
            text-transform: uppercase;
            color: #3456D0;
            margin-bottom: 25px;
        }

        .mainlower {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-top: 35px;
            padding-bottom: 35px;
            border-top: 1px solid rgba(52, 86, 208, 0.1);

            .border-none {
                border: none !important;
            }

            .innerlow {
                border-right: 1px solid rgba(52, 86, 208, 0.1);
                padding-right: 50px;

                .para {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 120%;
                    text-transform: uppercase;
                    color: #929CA7;
                    margin-bottom: 12px;
                }

                .head {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 120%;
                    color: #272E35;
                }
            }
        }
    }

    .btnlast {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

        .claimbtn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 12px 45px;
            background: radial-gradient(41.97% 87.5% at 50% 50%, #1FA6F5 0%, #324CF0 100%);
            border-radius: 40px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
        }
    }
}


.claimmodal {
    .modal-dialog {
        .modal-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px;
            width: 536px;
            background: #FFFFFF;
            border: 1px solid rgba(52, 86, 208, 0.1);
            backdrop-filter: blur(5px);
            border-radius: 20px;

            .modal-body {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 0px !important;

                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 120%;
                    text-align: center;
                    text-transform: uppercase;
                    color: #272E35;
                    margin-bottom: 35px;
                }

                .modalimg {
                    width: 155px;
                    height: 146px;
                    margin-bottom: 35px;
                }
            }

            .modal-footer {
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0px !important;

                .modalbtn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 14px 30px;
                    background: #EDEDED;
                    border-radius: 40px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    text-transform: uppercase;
                    color: #272E35;
                    white-space: nowrap;

                    &:hover {
                        background: radial-gradient(41.97% 87.5% at 50% 50%, #1FA6F5 0%, #324CF0 100%);
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}


@media (max-width:1024px) {
    .portal .uppertab {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    .portal .uppertab .innertab {
        border-right: none;
        padding-right: 0px;
        margin-bottom: 20px;
    }

    .portal .uppertab .innertab .para {
        text-align: center;
    }

    .portal .uppertab .innertab .head {
        text-align: center;
    }
}


@media (max-width:991px) {
    .portal .mainban .background {
        margin-bottom: 0px;
        background: url(../../Assets/mobilebackground.png) !important;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        padding: 20px 20px 0px 20px;
        border-radius: 45px;
    }

    .portal .mainban .innermain .padd {
        padding-top: 0px;
    }

    .portal .mainban .innermain .imgcard {
        margin-top: 90px;
    }

    .portal .cardpadd {
        padding: 0%;
    }

    .portal .mainban .heading .mainhead {
        font-size: 35px;
        margin-bottom: 30px;
    }
}

@media (max-width:768px) {
    .portal .lowertab .mainlower {
        justify-content: center;
        flex-direction: column;
    }

    .portal .lowertab .mainlower .innerlow {
        border-right: none;
        padding-right: 0px;
        margin-bottom: 20px;
    }

    .portal .lowertab .mainlower .innerlow .para {
        margin-bottom: 0px;
    }
}

@media (max-width:768px) {
    .claimmodal .modal-dialog .modal-content {
        width: 100%;
    }
}


.portal .gcctctc{
    margin-bottom: 120px;
}