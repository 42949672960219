.footer-main {
    // background: #eeeff2;
    padding: 20px 0px;
    border-top: 1.5px solid rgba(0, 0, 0, 0.05);
    .listLogos {
        img {
            width: 30px;
        }
    }
}

@media (max-width:576px){
.footer-main .logofooter{
    margin: 0 auto;
}
}